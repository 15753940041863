import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import BackgroundImage from "../../assets/images/hide.jpg";
import Header from "../layouts/header";
import Footer from "../layouts/footer";

const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const HeroSection = styled.header`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: ${zoomInOut} 10s infinite;
    z-index: 1;
  }
  .container {
    z-index: 3;
  }
`;
const HeroTitle = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 1rem;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const StyledDiv = styled.div`
  .section {
    margin: 50px 0;
  }
  .mission-values h3,
  h3 {
    color: #1a73e8; /* Adjust color as needed */
  }
  .signature {
    font-family: "Cursive", sans-serif;
    font-size: 24px;
  }
`;
export default function Faqs() {
  /* region Methods */
  const navigateMenu = (section) => {};
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  /* endregion Methods */
  return (
    <>
      <Header navigateMenu={navigateMenu} />
      <HeroSection
        className="header-image ken-burn-center light"
        data-parallax="true"
        data-natural-height="500"
        data-natural-width="1920"
        data-bleed="0"
        data-image-src={BackgroundImage}
        data-offset="0"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeroTitle>FAQs</HeroTitle>
              <HeroSubtitle>Frequently Asked Questions</HeroSubtitle>
            </div>
          </div>
        </div>
      </HeroSection>
      <StyledDiv>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="termsComponent section">
                <h3 className="topTermsHeading">FAQs</h3>
                <p className="topParas">Frequently Asked Questions</p>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          1. How much time will it take to get my loan?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          If approved as per your eligibility, loans are
                          disbursed within 24 hours for personal loans. And,
                          while getting business loans, it may take up to two
                          working days.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          2. Is there a requirement for collateral?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          No, our loans are unsecured and are approved based on
                          credit score and Income. However, in some cases,
                          especially for larger amounts, collateral may be
                          required.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          3. What are the interest rates?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Our interest rates start from as low as 9% per annum
                          and it will depend on the loan type and your
                          eligibility.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          4. Can I repay my loan early?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Yes! There are no prepayment penalties. You can repay
                          the loan before your repayment date, especially to
                          reduce the interest.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          5. What is the interest rate applied on loans?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          The interest rate on the loan depends on the type of
                          loan, loan amount, and tenure. We offer competitive
                          rates based on your credit score and income as well.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          6. what is the maximum time required for loan
                          approval?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          If your income and credit score are higher, and you
                          have the complete eligibility criteria, the loan
                          approval doesn’t time. However, a business loan can
                          take a few days, especially due to the verification.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          7. Can I apply for a loan with a low credit score?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          Yes, you can. Because we provide loans for them having
                          a good income but a lower credit score. If you have
                          the capability to repay the loan we provide the loan
                          with instant approval.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          8. What are the repayment options?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <p>
                          We have flexible EMI options, including monthly,
                          quarterly, and customized repayment plans so that you
                          can pay the loan easily at your convenience.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </StyledDiv>
      <Footer className={"footer_section custom_footer_style"} />
    </>
  );
}
