import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
function TermsOfUse() {
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  const dynamicClassName = "footer_section custom_footer_style";
  return (
    <>
      <Header />
      <div className="termsWrapper">
        <div className="termsContent">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="termsComponent">
                  <h3 className="topTermsHeading">Terms & Conditions</h3>
                  <p className="topParas">
                    PLEASE CAREFULLY READ THESE TERMS AND CONDITIONS ("Terms").
                    THESE TERMS CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND
                    finlab.one. YOU ACCEPT AND AGREE TO THE TERMS CONTAINED
                    HEREIN BY CLICKING "I ACCEPT" OR "I AGREE," OR BY
                    DOWNLOADING, INSTALLING, OR OTHERWISE USING THE SERVICES. DO
                    NOT CLICK "I ACCEPT" OR "I AGREE" OR DOWNLOAD, INSTALL, OR
                    USE THE SERVICES IF YOU DO NOT AGREE TO THESE TERMS.{" "}
                  </p>

                  <h3 className="topTermsHeading">Definition(s)</h3>
                  <ul className="termsBullets">
                    <li>
                      "Application or App." or “Website” shall mean Finlab.One
                      the mobile application/website created, developed and
                      designed by the Company for providing the Services.
                    </li>
                    <li>
                      "Company" or "we" shall mean BYND FINSERVE CONSULTANT
                      PRIVATE LIMITED (U66190HR2023PTC114534), a company
                      incorporated under the Companies Act, 2013 having its
                      registered office at Plot No 19, Second Floor, Sector -33
                      Arjun Nagar, Gurgaon - 122001
                    </li>
                    <li>
                      Any individual who accesses, downloads, uses, or displays
                      the Platform and Services is referred to as a
                      "Customer(s)" or "you" or "End-User."
                    </li>
                    <li>
                      "Loan" refers to the loan that you may apply for on the
                      Platform and that LENDER approves and grants, subject to
                      the Loan Agreement's terms and conditions.
                    </li>
                    <li>
                      "Loan Agreement" refers to the loan agreement between
                      LENDER and the Customer(s) for the purpose of issuing the
                      Loan, whether in physical or electronic form, as
                      applicable from time to time.
                    </li>
                    <li>
                      "Online Stores" refers to the Windows Store, Android
                      Google Play, iOS App Store, and any other online store or
                      gateway where the Company makes the App available to
                      End-Users on a regular basis.{" "}
                    </li>
                    <li>
                      "Outstanding Amount(s)"(s) refers to the Loan, interest,
                      and charges due and payable by you to LENDER on the
                      respective due date.
                    </li>
                    <li>
                      "Platform" refers to both the App and the Website as a
                      whole.
                    </li>
                    <li>
                      "Services" refers to LENDER's services of issuing,
                      approving, and financing emergency loans via the Platform.
                    </li>
                    <li>
                      Social networking sites such as Facebook, LinkedIn, and
                      other similar platforms are referred to as "Third Party
                      Platforms."
                    </li>
                    <li>
                      Any data, information, documents, or materials supplied to
                      the Company prior to or during the use of the Services are
                      referred to as "User Data."
                    </li>
                    <li>
                      "Website" shall mean www.Finlab.One.com, managed and
                      operated by the Company for the provision of Services.
                    </li>
                  </ul>
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            1. PRICING POLICY
                          </button>
                        </h2>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            An illustration of how Loan fees and charges may be
                            levied
                          </p>
                          <ul className="termsBullets">
                            <li>Loan amount : ₹50,000 </li>
                            <li>
                              Processing Fee (2 % of Loan amount X 18% GST) :
                              ₹1,180{" "}
                            </li>
                            <li>Total Loan Amount : ₹51,180 </li>
                            <li>
                              Interest : 24% p.a. (on fixed principal balance
                              interest calculation){" "}
                            </li>
                            <li>Tenure : 12 months </li>
                            <li>Your EMI : ₹4,840 </li>
                            <li>
                              Total amount to be paid : ₹4,840 X 12 = ₹58,075{" "}
                            </li>
                            <li>
                              Total interest paid : ₹58,075 - ₹51,180 = ₹6,895{" "}
                            </li>
                            <li>
                              Total cost of loan : ₹6,895 + ₹1,180 = ₹8,075{" "}
                            </li>
                            <li>APR (Annual Percentage Rate) : 28.58% </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            2. SERVICES
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            You'll apply for the Loan through the Services,
                            provided you meet the qualifying requirements
                            outlined in the Mobile App. You acknowledge that
                            LENDER has appointed the corporation to collect,
                            authenticate, track your location, verify, and
                            ensure the User Data, papers, and data that LENDER
                            may demand in order to approve the Loan. LENDER
                            enables the business to collect and retain User Data
                            through the Platform's mobile form ("Mobile
                            Application Form"). You must register with the
                            company by logging in through your Third Party
                            Platforms in order to use the services ("User
                            Account"). You will be needed to share and upload
                            User Data on the Mobile form during the application
                            process. User Data shall include personal
                            information including but not limited to your name,
                            e-mail address, gender, date of birth, mobile
                            number, passwords, photograph, mobile information
                            including contact numbers, SMS and browsing history,
                            data and login-in credentials of Third Party
                            Platforms, financial information like bank
                            documents, salary slips, bank statements, PAN card,
                            checking account no., data from Credit Information
                            Companies, data needed to comply with Know Your
                            Customer regulations, as well as other pertinent
                            information ("Personal Information"). You agree that
                            the private Information shall always be accurate,
                            correct and complete. As a part of the Services, you
                            authorize us to import your details and private
                            Information dispersed over Third Party Platforms.
                            You understand and acknowledge that we may
                            periodically request for updates on such Personal
                            Information and that we may receive such updated
                            information from Third Party Platforms.
                          </p>
                          <p>
                            All transactions undertaken on your behalf by the
                            corporate are going to be on the idea of your
                            express instructions/consent and can be strictly on
                            a non-discretionary basis. You also authorize the
                            corporate to urge your credit information report
                            from one or more Credit Information Companies as
                            decided by the corporate from time to time. Once you
                            verify and upload the User Data and/or other
                            documents and details within the Mobile form, the
                            corporate shall process an equivalent. Upon the
                            completion of the document verification by the
                            corporate, the Loan could also be sanctioned by
                            LENDER to you, subject to fitting eligibility
                            criteria and other conditions set forth by LENDER
                            for sanctioning the Loan. Thereafter, you're
                            required to fill and upload the ECS/NACH mandate
                            form/Cheque or the other document as could also be
                            required by LENDER. The Company has the right to
                            gather tangible documents, including signatures on
                            such papers, that are essential for the Loan to be
                            sanctioned and processed. Upon the gathering of
                            documents by the corporate, LENDER shall disburse
                            the Loan subject to the terms and conditions of the
                            Loan Agreement.
                          </p>
                          <p>
                            The sanctioned Loan shall be disbursed as per the
                            mode provided within the Mobile form. You are
                            required to repay the Outstanding Amount(s) to
                            LENDER, on the respective due date(s) mentioned
                            within the Mobile form.
                          </p>
                          <p>
                            The sanctioned Loan shall be disbursed as per the
                            mode provided within the Mobile form. You are
                            required to repay the Outstanding Amount(s) to
                            LENDER, on the respective due date(s) mentioned
                            within the Mobile form.
                          </p>
                          <p>
                            You understand and acknowledge that the corporate
                            reserves the proper to trace your location ("Track")
                            during the supply of Services, and also within the
                            event that you simply stop, cease, halt using or
                            availing of the Services, whether by deleting or
                            uninstalling the Mobile App or otherwise, until your
                            obligations to pay the Outstanding Amount(s) to
                            LENDER have been met. Our Services' deletion,
                            uninstallation, or termination do not relieve you of
                            your responsibility, obligation, or liability to
                            repay the Outstanding Amount (s).
                          </p>
                          <p>
                            You understand and acknowledge that you simply shall
                            be solely liable for all the activities that occur
                            under your User Account while availing the Services.
                            You undertake that the corporate shall not be
                            responsible and responsible for any claims, damages,
                            disputes arising out of use or misuse of the
                            Services. By usage of the Services, you shall be
                            solely liable for maintaining the confidentiality of
                            the User Account and for all other related
                            activities under your User Account. The Company
                            reserves the proper to simply accept or reject your
                            registration for the Services without obligation of
                            explanation.
                          </p>
                          <p>
                            You understand and acknowledge that, you're solely
                            liable for the potential of the electronic devices
                            and therefore the internet connection, you chose to
                            run the Platform. The Platform’s operation or the
                            Services on your device is subject to availability
                            of hardware, software specifications, internet
                            connection and other features and specifications,
                            required from time to time.
                          </p>
                          <p>
                            The User Data provided during the registration is
                            stored by the corporate for your convenience. You
                            are not required to log-in to your User Account,
                            whenever, to use or access the Platform. By
                            accepting these Terms, you permit us to track,
                            retrieve, and utilize User Data, including but not
                            limited to your Personal Information, for the
                            purposes of authentication and any modifications to
                            your credentials.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            3. LICENSE
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            License to use the App: In order to use the
                            Services, you're required to download and install
                            the App. For this reason, you represent and warrant
                            that you are of legal age in accordance with the
                            laws that apply to you, and that you are capable of
                            understanding, entering into, and complying with
                            these Terms. You have a limited, non-exclusive,
                            non-transferable, non-sub licensable, and revocable
                            permission to download, install, and use the App
                            granted by the Company. The App is licensed to you
                            rather than sold to you, and you may only use it in
                            accordance with these Terms.
                          </p>
                          <p>
                            Maintenance and Support: You understand that, while
                            the Company may, in its sole discretion, offer
                            maintenance and support for the App from time to
                            time, the Company is under no obligation to do so.
                          </p>
                          <p>
                            Updates/Upgrades: We may release new
                            updates/upgrades for the App; you will be notified
                            via the online Stores. If you choose not to update
                            or upgrade the App, you will be unable to access
                            some features or functions.{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingFour">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            4. RESTRICTIONS
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFour"
                        className="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>You agree not to: </p>
                          <ul className="termsBullets">
                            <li>
                              to commit fraud, embezzlement, concealment, or any
                              other illicit and/or illegal purpose;{" "}
                            </li>
                            <li>
                              to reproduce, duplicate, copy, sell, resell, or
                              exploit any component of the App;{" "}
                            </li>
                            <li>
                              upload, post, email, transmit or otherwise make
                              available any content that's unlawful, harmful,
                              threatening, abusive, harassing, torturous,
                              defamatory, vulgar, obscene, libelous, invasive of
                              another’s privacy, hateful, or racially,
                              ethnically or otherwise objectionable through the
                              Platform;{" "}
                            </li>
                            <li>
                              utilize the Platform with the purpose of causing
                              harm or injury to a third party;{" "}
                            </li>
                            <li>
                              impersonate any person or entity, on the Platform;
                            </li>
                            <li>
                              forge headers or otherwise manipulate identifiers
                              so as to disguise the origin of any content
                              transmitted through the App;
                            </li>
                            <li>
                              upload, post, email, transmit or otherwise make
                              available any content that you simply don't have a
                              right to form available under any law or under
                              contractual or fiduciary relationships (such as
                              details, proprietary and tip learned or disclosed
                              as a part of employment relationships or under
                              nondisclosure agreements);
                            </li>
                            <li>
                              upload, post, email, transmit or otherwise make
                              available on the Platform, any content that
                              infringes any patent, trademark, secret, copyright
                              or other proprietary rights of any party;{" "}
                            </li>
                            <li>
                              Any unsolicited or illegal advertising,
                              promotional materials, "junk mail," "spam," "chain
                              letters," "pyramid schemes," or other type of
                              solicitation may be uploaded, posted, emailed,
                              transmitted, or otherwise made accessible on the
                              Platform;{" "}
                            </li>
                            <li>
                              Any content that contains software viruses or
                              other code, files, or programs intended to
                              disrupt, damage, or restrict the operation of any
                              computer software or hardware or
                              telecommunications equipment may be uploaded,
                              posted, emailed, transmitted, or otherwise made
                              accessible on the Platform;{" "}
                            </li>
                            <li>
                              disrupt the traditional flow of or otherwise act
                              during a manner that negatively affects other
                              user’s ability to interact in real time exchanges;
                            </li>
                            <li>
                              Interfere with or disrupt the Platform, servers,
                              or networks linked to the Platform, or disobey any
                              network requirements, procedures, rules, or
                              regulations; deliberately or unintentionally
                              violate any relevant local, state, national, or
                              international laws and regulations having legal
                              effect.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingFive">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive"
                          >
                            5. CONTENT AVAILABLE
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFive"
                        className="collapse"
                        aria-labelledby="headingFive"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            You acknowledge that the corporate makes no
                            representations or warranties about the fabric,
                            data, and knowledge, like data files, text, facts
                            and figures, computer software, code, audio files or
                            other sounds, photographs, videos, or other images
                            (collectively, the "Content") which you'll have
                            access to as a part of the Services, or through your
                            use of the Platform. Under no circumstances, shall
                            the corporate be liable in any way for any Content,
                            including, but not limited to any infringing
                            Content, any errors or omissions in Content, or for
                            any loss or damage of any kind incurred as a results
                            of the utilization of any Content posted,
                            transmitted, linked from, or otherwise accessible
                            through or made available via the Platform. The
                            Content on the Platform shouldn't be considered a
                            suggestion, solicitation, invitation, advice or
                            recommendation to shop for or sell investments,
                            securities or the other instrument or financial
                            products / schemes of the corporate (including its
                            affiliates), unless expressly covered in these
                            Terms.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingSix">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSix"
                            aria-expanded="false"
                            aria-controls="collapseSix"
                          >
                            6. PROPRIETARY RIGHTS OF THE COMPANY
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseSix"
                        className="collapse"
                        aria-labelledby="headingSix"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            You understand, acknowledge and agree that the
                            corporate is that the sole owner of all rights,
                            title and interest, including any and every one
                            property rights within the Content, Platform,
                            Services, logos, trade names, brand names, designs
                            and any necessary software utilized in reference to
                            the Platform.{" "}
                          </p>

                          <p>
                            There could also be proprietary logos, service marks
                            and trademarks found on the Platform whether
                            owned/used by the corporate or otherwise. By
                            displaying them on the Platform, the corporate isn't
                            granting you any license to utilize the proprietary
                            logos, service marks, or trademarks. Any
                            unauthorized use of an equivalent may violate
                            applicable property laws.
                          </p>

                          <p>
                            You understand and acknowledge that the Platform is
                            owned by the corporate. Nothing under these Terms
                            shall be deemed to be a transfer in ownership,
                            rights, title, from the corporate to you or any
                            third party, within the Platform. During the
                            validity of your registration with the Company, you
                            are entitled to use the Company's services.{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingSeven">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSeven"
                            aria-expanded="false"
                            aria-controls="collapseSeven"
                          >
                            7. LINKS TO THIRD PARTY SITES
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseSeven"
                        className="collapse"
                        aria-labelledby="headingSeven"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            The Platform may contain links to other websites
                            owned and operated by third parties who aren't
                            associated with the Platform ("Linked Websites").
                            The Linked Websites aren't under the control of the
                            corporate and therefore the Company shall not be
                            liable for the content of any Linked Websites or any
                            hyperlink contained in a Linked Website and makes no
                            representation or warranty with reference to the
                            content of any such third party sites.{" "}
                          </p>

                          <p>
                            The Platform provides these links to you as a
                            convenience only and therefore the inclusion of any
                            link doesn't imply any endorsement of the Linked
                            Website by the corporate. It is totally at your own
                            responsibility to access or use such a Linked
                            Website. The Company shall not be a celebration to
                            any transaction between you and therefore the Linked
                            Website. Your use of a Linked Website is subject to
                            those terms and conditions of that respective Linked
                            Website.
                          </p>

                          <p>
                            The Platform can also contain third party
                            advertisements, if any. The display of such
                            advertisements doesn't in any way imply an
                            endorsement or recommendation by/of the relevant
                            advertiser, its products or services. You shall
                            independently ask the relevant advertiser for all
                            information regarding the advertisement and its
                            products and/or services. The Company accepts no
                            responsibility for any interaction between you and
                            therefore the relevant third party and is released
                            from any liability arising out of or in any way
                            connected with such interaction.{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingEight">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseEight"
                            aria-expanded="false"
                            aria-controls="collapseEight"
                          >
                            8. CANCELLATION AND REFUND POLICY
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseEight"
                        className="collapse"
                        aria-labelledby="headingEight"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <h4>Cancellation</h4>
                          <p>
                            While granting loans every attempt is made to do
                            proper assessment and scrutiny on case to case
                            basis. However the Finlab.One has all rights to
                            cancel the sanction letter if it is found that the
                            applicant has provided incorrect/ false information
                            and or submitted faulty/ fake/ improper documents to
                            mislead the Finlab.One. The cancellation will be
                            done, at any time before or after the disbursement
                            (full or partial) of the loan amount. The Finlab.One
                            reserves the right to recall the entire amount
                            disbursed along with interest and penalties which
                            the Finlab.One may deem fit and the entire amount
                            will be payable to the Finlab.One in one single
                            installment immediately on demand. The Finlab.One
                            will have every right to take any legal/ criminal
                            action against a person/ entity who has tried to
                            avail any loan facility through such
                            misrepresentation/ fraud.{" "}
                          </p>

                          <p>
                            In the event that the Finlab.One discovers that the
                            borrower has utilized the loan amount for a purpose
                            that was not intended as per the loan application
                            form, or if the end use of the loan is not confirmed
                            by the borrower, the Finlab.One reserves the right,
                            at its discretion, to cancel the loan sanction and
                            recall the entire loan amount disbursed, along with
                            interest and penalties. If the borrower fails to
                            repay the due amount in a single installment, the
                            Finlab.One will be entitled to take legal and/or
                            criminal action against the borrower as described
                            above.
                          </p>
                          <p>
                            The Finlab.One will have every right to amend the
                            terms of sanction/ to reduce the amount/ period/
                            interest rate as per sanction at any time before or
                            after the disbursement of the loan. The borrower
                            will have no recourse against this decision of the
                            Finlab.One. However the Finlab.One has every right
                            to cancel the sanction letter and recall the entire
                            loan without assigning any reason whatsoever.
                          </p>
                          <p>
                            At the end of the repayment period the entire
                            outstanding balance will become due and payable on
                            the due date. Extension of repayment will be granted
                            only in extra-ordinary circumstances i.e. Natural
                            Calamity/ Natural Disaster etc.
                          </p>

                          <h4>Refund</h4>
                          <p>
                            If an excess amount is deposited in the loan account
                            at the end of the repayment period and there is any
                            credit balance in the loan account of the borrower
                            it will be refunded back on being brought to the
                            notice of the Finlab.One.
                          </p>
                          <p>
                            The Finlab.One will not be responsible in the event
                            of any failed transaction of crediting loan
                            installment through debit card due to any reasons
                            whatsoever.
                          </p>
                          <p>
                            If any repayment installment is wrongly credited to
                            different account other than the one intended for it
                            will appropriated to the relevant account after it
                            is brought to the notice of the Finlab.One. The
                            Finlab.One will not be responsible for any such
                            instances and the consequent loss to the borrower
                            whatsoever. In the event of any such wrong credit
                            through debit card, the relevant entry will be
                            appropriated within fifteen working days from the
                            date it is brought to the notice of the Finlab.One.
                          </p>
                          <p>
                            The Finlab.One reserves all rights to change/ amend/
                            add/ cancel any/ all of the above rules at any time
                            at its sole discretion without any prior notice
                            whatsoever.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingNine">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseNine"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            9. TERMINATION
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseNine"
                        className="collapse"
                        aria-labelledby="headingNine"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            The Company reserves its rights to terminate these
                            Terms within the event:{" "}
                          </p>
                          <ul className="termsBullets">
                            <li>you breach any provision of those Terms; </li>
                            <li>
                              the Company is required to try to so under law; or{" "}
                            </li>
                            <li>
                              the Company chooses to discontinue the Services
                              being offered or discontinue to work the Platform;{" "}
                            </li>
                            <li>
                              the license granted to use the App expires;{" "}
                            </li>
                            <li>of non-payment of Outstanding Amount(s).</li>
                            <li>
                              The Company retains the right to pursue you until
                              you have fulfilled all of your obligations,
                              including but not limited to payment of the
                              Outstanding Amount, even if you have uninstalled
                              the App or the Terms have expired (s).
                            </li>
                          </ul>
                          <p>
                            Upon termination of those Terms, the rights and
                            licenses granted to you under these Terms shall
                            cease to exist, and you want to forthwith stop using
                            the Platform and therefore the Services and repay
                            the Outstanding Amount(s). Notwithstanding anything
                            contained in these Terms or otherwise, the
                            termination of those Terms for any reason
                            whatsoever, shall not affect your obligations,
                            including but not limited to repayment of the
                            Outstanding Amount(s).{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingTen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTen"
                            aria-expanded="false"
                            aria-controls="collapseNine"
                          >
                            10. DISCLAIMER OF WARRANTIES
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTen"
                        className="collapse"
                        aria-labelledby="headingTen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>You expressly understand and agree that: </p>

                          <p>
                            your use of the Services and therefore the Platform
                            is at your sole risk. The Platform, as well as the
                            Services, are offered "as is" and "as available."
                            The company explicitly disclaims any and all
                            warranties, explicit or implied, including, but not
                            limited to, implied warranties of merchantability,
                            fitness for a specified purpose, and
                            non-infringement. any material downloaded or
                            otherwise obtained through the access or use of the
                            Platform, is at your own discretion and risk which
                            you'll be solely liable for any damage to your
                            computing system, electronic data or loss of
                            knowledge that results from the download of any such
                            material. no advice or information, whether verbal
                            or written, obtained by you from the corporate, for
                            the Services or through the Platform shall create
                            any warranty not expressly stated in these Terms.
                            The Services are intended for private,
                            non-commercial use. You shall be solely liable for
                            the utilization, misuse, improper usage of the
                            Services and therefore the Platform. the corporate
                            shall not be responsible for any damages accruing
                            out of the utilization of the Services which haven't
                            been expressly stipulated under these Terms. the
                            corporate makes no warranty, including implied
                            warranty, and expressly disclaims any obligation,
                            that: (a) the Contents are and can be complete,
                            Exhaustive, accurate or suitable to your
                            requirements; (b) The Platform or the Services will
                            meet your requirements or are going to be available
                            on an uninterrupted, timely, secure, or error-free
                            basis; (c) the results which will be obtained from
                            the utilization of the Platform or Services are
                            going to be accurate or reliable.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingEleven">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseEleven"
                            aria-expanded="false"
                            aria-controls="collapseEleven"
                          >
                            11. INDEMNITY
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseEleven"
                        className="collapse"
                        aria-labelledby="headingEleven"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            You undertake to defend, identify, and keep harmless
                            the Company, its subsidiaries, affiliates, officers,
                            agents, co-branders or other partners, and employees
                            against any claim or demand., including attorneys’
                            fees, made by any third party thanks to or arising
                            out of (i) your violation of those Terms; (ii) your
                            violation of any rights of other users of the
                            Platform; (iii) your use or misuse of the Platform
                            or Services: (iv) your breach of relevant laws.{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingTwelve">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwelve"
                            aria-expanded="false"
                            aria-controls="collapseTwelve"
                          >
                            12. LIMITATIONS OF LIABILITY
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwelve"
                        className="collapse"
                        aria-labelledby="headingTwelve"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            You understand and agree that the corporate,
                            including its directors, officers, employees,
                            representatives, or the service provider, is not
                            liable for any direct, indirect, incidental,
                            special, consequential, or exemplary damages,
                            including but not limited to damages for loss of
                            profits, goodwill, use, data, or other intangible
                            losses (Even if the corporation has been advised
                            that such losses are possible), resulting from; (a)
                            use or inability to utilize the Services (b)
                            inability to use the Platform (c) failure or delay
                            in delivering the Services or access to the Platform
                            (d) the Company's performance or non-performance (e)
                            any damages to or viruses that may infect your
                            electronic devices or other property as a
                            consequence of your use of the Platform or any
                            material obtained through the Platform, and (f)
                            server failure or any other aspect of the Services.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingThirteen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThirteen"
                            aria-expanded="false"
                            aria-controls="collapseThirteen"
                          >
                            13. FORCE MAJEURE
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThirteen"
                        className="collapse"
                        aria-labelledby="headingThirteen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            Without limiting the foregoing, under no
                            circumstances shall the corporate be held
                            responsible for any damage, loss, loss of services
                            of Platform, thanks to deficiency in provision of
                            the Services resulting directly or indirectly from
                            acts of nature, forces, or causes beyond its
                            reasonable control, including, without limitation,
                            internet failures, computer equipment failures,
                            telecommunication system failures, change in
                            applicable regulations, including Federal Reserve
                            Bank of India regulations, or the other government
                            regulations, floods, storms, electrical failure,
                            civil disturbances, riots.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingFourteen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFourteen"
                            aria-expanded="false"
                            aria-controls="collapseFourteen"
                          >
                            14. PRIVACY POLICY
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFourteen"
                        className="collapse"
                        aria-labelledby="headingFourteen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            Our privacy policy covers the collection, sharing,
                            and uploading of Personal Information for the
                            provision of Services in great detail ("Privacy
                            Policy"). Our Privacy Statement may be found here.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingFifteen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseFifteen"
                            aria-expanded="false"
                            aria-controls="collapseFifteen"
                          >
                            15. CHANGE
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseFifteen"
                        className="collapse"
                        aria-labelledby="headingFifteen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            The Company reserves the proper to switch, change,
                            substitute, remove, suspend or update these Terms or
                            any information thereof at any time by posting the
                            updated Terms on the Platform. Such modifications
                            will take effect as soon as they are posted.
                            Following the revisions, your continued use of the
                            Services or Platform will be deemed your acceptance
                            of the amended Terms.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingSixteen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSixteen"
                            aria-expanded="false"
                            aria-controls="collapseSixteen"
                          >
                            16. CHOICE OF LAW AND JURISDICTION
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseSixteen"
                        className="collapse"
                        aria-labelledby="headingSixteen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            This Agreement shall be construed and governed by
                            the laws of India without reference to principles of
                            conflict of laws. The parties further agree that any
                            issues would be resolved exclusively by the courts
                            of Pune, Maharashtra, India.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingSeventeen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseSeventeen"
                            aria-expanded="false"
                            aria-controls="collapseSeventeen"
                          >
                            17. MISCELLANEOUS
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseSeventeen"
                        className="collapse"
                        aria-labelledby="headingSeventeen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            Entire Understanding: These Terms, alongside the
                            Loan Agreement, Mobile form and Privacy Policy,
                            constitute the whole understanding between you and
                            therefore the Company with reference to the
                            Services. Waiver: The failure of the corporate to
                            exercise or enforce any right or provision of those
                            Terms shall not constitute a waiver of such right or
                            provision. You agree that no matter any statute or
                            law to the contrary, any claim or explanation for
                            action arising out of or associated with availing of
                            the Services or these Terms must be filed within one
                            (1) year after such claim or explanation for action
                            arose or be forever barred. Severability: If any
                            provision of those Terms is found by a court of
                            competent jurisdiction to be invalid, the Parties
                            nevertheless agree that the court should endeavor to
                            offer effect to the Parties’ intentions as reflected
                            within the provision, and therefore the other
                            provisions of those Terms shall remain fully force
                            and effect.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingEighteen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseEighteen"
                            aria-expanded="false"
                            aria-controls="collapseEighteen"
                          >
                            18. REFERRAL PROGRAM
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseEighteen"
                        className="collapse"
                        aria-labelledby="headingEighteen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            Referrer being the person whose referral code has
                            been used and referral recipient being the person
                            who uses Finlab.One’s lending facility from a valid
                            referral code. Participant shall include, whether
                            individually or collectively, both the referrer and
                            therefore the referral recipient.{" "}
                          </p>
                          <p>
                            For each referral, both the referrer and therefore
                            the referral recipient get Rs. 200 Off on their next
                            loan repayment amounts. The said amount is subject
                            to change at Finlab.One’s discretion{" "}
                          </p>
                          <p>
                            In addition to the ₹ 200 off, referrers may be
                            eligible for referral contests that are declared
                            from time to time. To be eligible for lucky draw, 10
                            of the user’s friends must have availed a loan using
                            his/her referral code during the competition period.
                            Finlab.One holds the right to do the audit to
                            validate the eligibility for the lucky draw.
                            Referral code will be considered only if it is
                            applied through the App / Website of Finlab.One{" "}
                          </p>
                          <p>
                            Winners to this referral program authorizes
                            Finlab.One to publish its name, city, photos and
                            other information on various platforms.
                          </p>
                          <p>
                            Referral program is available for limited cities as
                            prescribed by Finlab.One. Creating multiple
                            Customer’s accounts using multiple address, emails
                            or signing in from multiple devices so as to unduly
                            gain Referral Discount shall be considered to be in
                            contravention of these Terms & Conditions.
                          </p>
                          <p>Referral discount is non-transferable.</p>
                          <p>
                            Finlab.One reserves the right to discontinue, add,
                            alter, withdraw, modify, any of the terms and
                            conditions of referral discount program without
                            assigning any reasons. In case of any dispute in
                            relation to referral program, Finlab.Ones decision
                            shall be binding on all customers.{" "}
                          </p>
                          <p>
                            Any taxes, cess, liabilities or charges payable
                            under the program to the govt or the other such
                            authority or body, if any, shall be borne directly
                            by the Customer.{" "}
                          </p>
                          <p>
                            Early Salary reserves the right to review and
                            investigate all referral activities and to suspend
                            accounts or revoke / remove referral discount and
                            withhold features or benefits obtained through
                            Finlab.One referral program.
                          </p>
                          <p>
                            Customer assumes all liability for any injury or a
                            damage caused or claimed to be caused by his or her
                            participation during this program and/or the
                            acceptance and/or use of any prize, and releases the
                            Finlab.One, and their respective subsidiaries,
                            affiliates, officers, directors, agents, and
                            employees, from any such liability. Finlab.One, and
                            their respective subsidiaries, affiliates, officers,
                            directors, agents and employees are not responsible
                            for technical, hardware, software, or telephone
                            malfunctions of any kind, lost or unavailable
                            network connections, or failed incorrect,
                            incomplete, inaccurate, electronic communications
                            that are garbled or delayed as a result of the user,
                            any of the equipment or programming connected with
                            or used, or any human mistake that may occur during
                            the processing of the entries in this programme.
                            Entry constitutes permission (except where
                            prohibited by law) to use the winner's name, city,
                            state, likeness and/or voice for purposes of
                            advertising, promotion and publicity without
                            additional compensation.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-header" id="headingNineteen">
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseNineteen"
                            aria-expanded="false"
                            aria-controls="collapseNineteen"
                          >
                            19. Get In Touch With Us:
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseNineteen"
                        className="collapse"
                        aria-labelledby="headingNineteen"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <p>
                            <strong>Call us : </strong> 9837010594
                          </p>
                          <p>
                            <strong>Email Us : </strong> care@Finlab.One
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h3>
                    <input
                      type="checkbox"
                      checked={true}
                      readOnly
                      className="checkmark"
                    />{" "}
                    I accept the terms and conditions{" "}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer className={dynamicClassName} />
    </>
  );
}
export default TermsOfUse;
