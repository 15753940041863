import AdminHeader from "../../layouts/admin-header";
import AdminFooter from "../../layouts/admin-footer";
import AdminNavBar from "../../layouts/admin-nav-bar";
import { useState, useEffect } from "react";
import Loader from "../../loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config/constant";
import Swal from "sweetalert2";
export default function BlogList({ menuAccess }) {
  const [loader, setLoader] = useState(false);
  const [blogList, setBlogList] = useState([]);
  const navigate = useNavigate();

  const deleteBlog = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        try {
          const response = await axios.put(`${API_URL}blog/blog/${id}/status`, {
            status: 90,
          });
          const { data, status } = response?.data;
          if (status === 200) {
            setBlogList(data);
            Swal.fire("Deleted!", "Blog has been deleted.", "success");
          }
        } catch (error) {
          Swal.fire("Error!", "There was an error deleting the blog.", "error");
        } finally {
          setLoader(false);
        }
      }
    });
  };

  const updateBlogStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to ${
        newStatus === 1 ? "activate" : "deactivate"
      } this blog.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        try {
          const response = await axios.put(`${API_URL}blog/blog/${id}/status`, {
            status: newStatus,
          });
          const { data, status } = response?.data;
          if (status === 200) {
            setBlogList(data);
            Swal.fire(
              "Updated!",
              "Blog status updated successfully.",
              "success"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an error updating the blog status.",
            "error"
          );
        } finally {
          setLoader(false);
        }
      }
    });
  };

  const fetchBlogList = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${API_URL}blog/blogs`);
      const { data, status } = response?.data;
      if (status === 200) {
        setBlogList(data);
      }
    } catch (error) {
      console.error("There was an error fetching the category list!", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchBlogList();
  }, []);
  return (
    <>
      {loader && <Loader />}
      <div className="layout-wrapper">
        <div className="layout-container">
          <AdminNavBar menuAccess={menuAccess} />
          <div className="adminMain-wrapper">
            <AdminHeader />
            <div className="mainContent">
              <div className="topHeadings mb-4">
                <h3>Blog List</h3>
              </div>
              <div className="contentBlocks">
                <div className="sectionTable">
                  <div className="continer-fluid">
                    <div className="row">
                      <div className="col-lg-12 col-md-9 col-xs-12">
                        <div className="card tableLead">
                          <div className="tableLeadCase">
                            <table
                              style={{ width: "100%" }}
                              className="table table-stripped"
                            >
                              <thead>
                                <tr>
                                  <th className="table-head">S.No.</th>{" "}
                                  <th className="table-head">Category</th>
                                  <th className="table-head">Image</th>
                                  <th className="table-head">Title</th>
                                  <th className="table-head">Description</th>
                                  <th className="table-head">Status</th>
                                  <th className="table-head">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {blogList.map((blog, index) => (
                                  <tr key={blog.id}>
                                    <td>{index + 1}</td>
                                    <td>{blog.category_name}</td>
                                    <td>
                                      <img
                                        src={blog.full_blog_image_url}
                                        style={{
                                          width: "100px",
                                          height: "auto",
                                        }}
                                        alt=""
                                      />
                                    </td>
                                    <td style={{ width: "20%" }}>
                                      {blog.title}
                                    </td>
                                    <td>
                                      {blog.content
                                        .replace(/<[^>]*>/g, "")
                                        .slice(0, 40)}
                                      ...
                                    </td>

                                    <td>
                                      {blog.status === 1
                                        ? "Active"
                                        : "Inactive"}
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-warning"
                                        title="Edit Category"
                                        onClick={() => {
                                          if (
                                            menuAccess?.superAdmin ||
                                            menuAccess?.permissions?.manageBlog
                                              ?.editBlog
                                          ) {
                                            navigate(
                                              `/admin/manageBlog/edit/${blog.id}`
                                            );
                                          } else {
                                            Swal.fire(
                                              "Permission Denied",
                                              "You don't have permission to edit the blog.",
                                              "error"
                                            );
                                          }
                                        }}
                                      >
                                        <i
                                          className="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <button
                                        className={`btn btn-sm ${"btn-primary"}`}
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                          if (
                                            menuAccess?.superAdmin ||
                                            menuAccess?.permissions?.manageBlog
                                              ?.activeInactiveBlog
                                          ) {
                                            updateBlogStatus(
                                              blog.id,
                                              blog.status
                                            );
                                          } else {
                                            Swal.fire(
                                              "Permission Denied",
                                              "You don't have permission to change the blog status.",
                                              "error"
                                            );
                                          }
                                        }}
                                        title={
                                          blog.status === 1
                                            ? "Inactivate blog"
                                            : "Activate blog"
                                        }
                                      >
                                        {blog.status === 1 ? "☓" : "✓"}
                                      </button>
                                      <button
                                        className="btn btn-sm btn-danger"
                                        style={{ marginLeft: "5px" }}
                                        onClick={() => {
                                          if (
                                            menuAccess?.superAdmin ||
                                            menuAccess?.permissions?.manageBlog
                                              ?.deleteBlog
                                          ) {
                                            deleteBlog(blog.id);
                                          } else {
                                            Swal.fire(
                                              "Permission Denied",
                                              "You don't have permission to delete the blog.",
                                              "error"
                                            );
                                          }
                                        }}
                                        title="Delete Blog"
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}
