import React, { useEffect } from "react";
import BackgroundImage from "../../assets/images/hide.jpg";
import ImageBusinessLoan from "../../assets/images/Business-Loan.jpg";
import styled, { keyframes } from "styled-components";
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
import { Helmet } from "react-helmet";
const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const HeroSection = styled.header`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: ${zoomInOut} 10s infinite;
    z-index: 1;
  }
  .container {
    z-index: 3;
  }
`;
const HeroTitle = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 1rem;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const StyledDiv = styled.div`
  .section {
    margin: 50px 0;
  }
  .mission-values h3,
  h3 {
    color: #1a73e8; /* Adjust color as needed */
  }
  .signature {
    font-family: "Cursive", sans-serif;
    font-size: 24px;
  }
`;
const BusinessLoanContent = () => {
  const dynamicClassName = "footer_section custom_footer_style";
  const navigateMenu = (section) => {};
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Get a Business Loan with FinLab One | Apply Now</title>
        <meta
          name="title"
          content="Get a Business Loan with FinLab One | Apply Now"
        />
        <meta
          name="description"
          content="Apply for a Business Loan online with FinLab One. No Hidden Charges, Competitive Interest Rates, Transparent & Simple Process, Flexible Loan Terms."
        />
        <link rel="canonical" href="https://www.finlab.one/business-loan" />
        <meta
          name="keywords"
          content="business loan, small business loan, business financing, business loan options, financial solutions for businesses"
        />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LoanOrCredit",
              "name": "Business Loan",
              "description": "Customized business loan solutions with competitive rates and flexible terms to help your business grow.",
              "loanType": "Business Loan",
              "loanTerm": "Flexible terms",
              "interestRate": "5.5%",
              "amount": "Up to ₹500,000",
              "url": "https://finlab.one/business-loan"
            }
          `}
        </script>
      </Helmet>
      <Header navigateMenu={navigateMenu} />
      <HeroSection
        className="header-image ken-burn-center light"
        data-parallax="true"
        data-natural-height="500"
        data-natural-width="1920"
        data-bleed="0"
        data-image-src={BackgroundImage}
        data-offset="0"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeroTitle>Business Loan</HeroTitle>
              <HeroSubtitle>
                Flexible Financing Solutions to Grow Your Business
              </HeroSubtitle>
            </div>
          </div>
        </div>
      </HeroSection>
      <StyledDiv>
        <div className="section">
          <div className="container">
            <h3>
              Let your business have a financial boost with a business loan
            </h3>
            <p>
              <img
                src={ImageBusinessLoan}
                alt="Business Loan"
                style={{ width: "100%" }}
              />
            </p>
            <p>
              Starting a business or managing financial requirements becomes
              easier when you have sufficient financial assistance, and our loan
              for business startups is easily available. We are providing a
              business loan whether to start a business or maintain the existing
              one. With affordable interest and an easy application process, our
              business loan is available for all eligible entrepreneurs willing
              to start their business and want to get help from a trusted
              financial partner.
            </p>

            <p>
              There are certain eligibility criteria such as a good credit
              score, a turnover of business, or a precise business plan along
              with financial strength and considering all these, we provide an
              easy and affordable business loan.
            </p>
            <h3>Fueling Your Business Growth:</h3>
            <p>
              Whether you have a startup business or an established enterprise,
              we provide business loans to help you expand the business, buy
              equipment, and also to manage cash flow for your business.{" "}
            </p>
            <h3>Key Features of Our Business Loan:</h3>
            <ul>
              <li>Loans starting from ₹50,000 to ₹200,00,000 </li>
              <li>Competitive interest rates </li>
              <li>Simple documentation and quick processing </li>
              <li>Flexible repayment options </li>
            </ul>

            <h3>Why choose us for a business loan</h3>
            <p>
              Anyone willing to choose a business loan has different things to
              consider, especially the features of the loan and we can assure
              you that we facilitate our services with all those features that
              make our business loan distinct. Some essential features of our
              business loan are
            </p>
            <ul>
              <li>
                <strong>Fast Approval:</strong> We have an instant verification
                and fast approval process because our executives immediately
                start the verification after receiving your loan application.
              </li>
              <li>
                <strong>Multiple Borrowing Options:</strong> For business, we
                have multiple borrowing options, where you can borrow an
                unsecured loan and a loan against property as well.
              </li>
              <li>
                <strong>Affordable Interest:</strong> We are providing the
                business loan with affordable interest which is competitive and
                can be lower as per your higher credit score.
              </li>
              <li>
                <strong>No hidden fees:</strong> We are not charging any
                additional fees other than interest and processing fees.
                Therefore, we have a fair and transparent business loan.
              </li>
              <li>
                <strong>Flexible tenure and repayment options:</strong> You can
                choose flexible tenure and repayment options so that you will be
                able to repay easily at your convenience.
              </li>
              <li>
                <strong>Easy Application:</strong> It’s the best feature that
                makes our business loan-specific. Therefore, you can easily
                apply for a loan for a business startup.
              </li>
            </ul>

            <h3>Steps to apply for business loan</h3>
            <ol>
              <li>Go to the application and click on Apply Now</li>
              <li>Fill personal and professional details in the form</li>
              <li>Upload documents that are required for the business loan</li>
              <li>Get the approval after the verification</li>
              <li>Follow instructions, accept the terms & conditions</li>
              <li>Get the disbursal of your loan amount</li>
            </ol>

            <h3>Eligibility Criteria</h3>
            <p>
              You can get acquainted with your eligibility with the eligibility
              criteria which have been updated as per the lender’s terms and
              conditions.{" "}
            </p>
            <table border="1">
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Factors</th>
                  <th>Eligibility Criteria</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Age</td>
                  <td>
                    The age of the borrowers should be above 21 Years and up to
                    65 Years
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Income</td>
                  <td>The turnover of business must be higher than 20 lacs</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Credit score</td>
                  <td>650</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Nationality</td>
                  <td>Indian</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Terms of Business</td>
                  <td>
                    Borrower must have a registered business or must have a
                    business plan
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Documents Required</td>
                  <td>
                    Aadhar card, PAN, Bank statement, ITR, utility bills,
                    Address Proof and business plans
                  </td>
                </tr>
              </tbody>
            </table>

            <h3>Documents Required</h3>
            <h5>Identity Proof</h5>
            <ul>
              <li>Aadhar Card</li>
              <li>PAN</li>
              <li>Passport</li>
            </ul>

            <h5>Transactional and Financial Proof</h5>
            <ul>
              <li>Bank Statement - 12 Months</li>
              <li>ITR - last 3 years</li>
              <li>GST registration</li>
              <li>Udyam Aadhar</li>
              <li>MSME Certificate</li>
            </ul>

            <h5>Residential Proof</h5>
            <ul>
              <li>Rent Agreement, Utility bills, Address of business</li>
            </ul>
          </div>
        </div>
      </StyledDiv>
      <Footer className={dynamicClassName} />
    </>
  );
};

export default BusinessLoanContent;
