import { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import BackgroundImage from "../../assets/images/hide.jpg";
import FirstImage from "../../assets/images/first.jpg";
import SecondImage from "../../assets/images/second.jpg";
import ThirdImage from "../../assets/images/third.jpg";
import Signature from "../../assets/images/signature.png";
import Carousel from "react-bootstrap/Carousel";
const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const HeroSection = styled.header`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: ${zoomInOut} 10s infinite;
    z-index: 1;
  }
  .container {
    z-index: 3;
  }
`;
const StyledDiv = styled.div`
  .section {
    margin: 50px 0;
  }
  .mission-values h3,
  h3 {
    color: #1a73e8; /* Adjust color as needed */
  }
  .signature {
    font-family: "Cursive", sans-serif;
    font-size: 24px;
  }
`;
const HeroTitle = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 1rem;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export default function AboutUs() {
  /* region Methods */
  const navigateMenu = (section) => {};
  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);
  /* endregion Methods */
  return (
    <>
      <Header navigateMenu={navigateMenu} />
      <HeroSection
        className="header-image ken-burn-center light"
        data-parallax="true"
        data-natural-height="500"
        data-natural-width="1920"
        data-bleed="0"
        data-image-src={BackgroundImage}
        data-offset="0"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeroTitle>About Finlab.One</HeroTitle>
              <HeroSubtitle>
                Revolutionizing Financial Technology for Businesses and
                Individuals
              </HeroSubtitle>
            </div>
          </div>
        </div>
      </HeroSection>
      <StyledDiv className="container">
        <div className="row section">
          <div className="col-md-12">
            <h3>About Finlab.one</h3>
          </div>
          <div className="col-md-4">
            <p>
              At Finlab. One, we have a mission to make our lending services
              easy and accessible for all those willing to get instant financial
              support whether to short out household needs or to expand the
              existing business. We provide loans for different purposes such
              as, business loans, personal loans, car loans, home loans and
              others. For all these loan requirements, we are always available.
            </p>
          </div>
          <div className="col-md-4">
            <p>
              Finlab.One, which was founded in 2023, has been facilitating its
              loan service for all eligible clients and as per the financial
              requirement, it has been delivering its easy and accessible loan
              for all eligible customers for all the aforesaid services.
            </p>
          </div>
          <div className="col-md-4">
            <Carousel fade interval={3000} controls={false}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={FirstImage}
                  alt={`Slide ${FirstImage}`}
                  style={{ height: "225px", objectFit: "cover" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={SecondImage}
                  alt={`Slide ${SecondImage}`}
                  style={{ height: "225px", objectFit: "cover" }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={ThirdImage}
                  alt={`Slide ${ThirdImage}`}
                  style={{ height: "225px", objectFit: "cover" }}
                />
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div
          className="section text-center mission-values"
          style={{ padding: "0px 15px" }}
        >
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ display: "flex" }}>Our Mission</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <p style={{ position: "relative", textAlign: "left" }}>
                Our mission is simple: to make lending easy, affordable, and
                accessible for everyone. At Finlab.One, we believe that
                financial opportunities should not be limited by complexity or
                exclusivity. By leveraging cutting-edge technology and
                innovative solutions, we aim to simplify the borrowing process
                and remove barriers that prevent people from achieving their
                goals. Our focus is on providing transparent, fair, and
                customer-centric services tailored to individual needs. Whether
                it’s for personal growth, business expansion, or unforeseen
                challenges, we are here to empower you with seamless financial
                solutions that truly make a difference.
              </p>
            </div>
            <div className="col-md-4">
              <div className="box-sign no-margin">
                <div>
                  <img
                    alt="sign"
                    style={{
                      width: "150px",
                    }}
                    src={Signature}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span>Founder &amp; CEO</span>
                  <b>Vishal PParashar</b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="section text-center mission-values"
          style={{ padding: "0px 15px" }}
        >
          <div className="row">
            <div className="col-md-12">
              <h3 style={{ display: "flex" }}>Our Services</h3>
              <h5 style={{ display: "flex" }}>
                Loan Solutions for All Your Needs
              </h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p style={{ position: "relative", textAlign: "left" }}>
                We offer a variety of loan services that can suit your needs,
                whether personal or business loan:
                <ul>
                  <li>
                    <b>Personal Loans</b>
                    <ul>
                      <li>
                        <b>For Your Every Need:</b> Our personal loan is
                        available for multiple needs such as medical
                        emergencies, travel plans, home renovation, education,
                        or debt consolidation. It provides flexibility and
                        instant financial support, especially if you need to
                        manage your financial planning.
                      </li>
                      <li>
                        <b>Key Features:</b>
                        <ul>
                          <li>Loan amounts from ₹50,000 to ₹10,00,000</li>
                          <li>
                            Flexible repayment terms (6 months to 5 years)
                          </li>
                          <li>Quick disbursement (within 24 hours)</li>
                          <li>Minimal paperwork</li>
                          <li>Fast approval</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Business Loans</b>
                    <ul>
                      <li>
                        <b>Fueling Your Business Growth:</b> Whether you have a
                        startup business or an established enterprise, we
                        provide business loans to help you expand the business,
                        buy equipment, and also to manage cash flow for your
                        business.
                      </li>
                      <li>
                        <b>Key Features:</b>
                        <ul>
                          <li>Loans starting from ₹50,000 to ₹200,00,000</li>
                          <li>Competitive interest rates</li>
                          <li>Simple documentation and quick processing</li>
                          <li>Flexible repayment options</li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </StyledDiv>
      <Footer className={"footer_section custom_footer_style"} />
    </>
  );
}
