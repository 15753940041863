import React from "react";

const CurrencyFormat = ({
  value,
  thousandSpacing,
  displayType,
  thousandSeparator,
  prefix,
}) => {
  // Function to format the number in Indian numbering system
  const formatNumber = (num) => {
    if (isNaN(num) || num === null || num === undefined) {
      return "";
    }

    // Convert number to string and split into parts
    const parts = num.toString().split(".");
    let integerPart = parts[0];
    const decimalPart = parts.length > 1 ? "." + parts[1] : "";

    // Handle thousand separator for Indian numbering system
    if (thousandSeparator) {
      const lastThree = integerPart.slice(-3);
      const otherNumbers = integerPart.slice(0, -3);
      const formattedOtherNumbers = otherNumbers.replace(
        /\B(?=(\d{2})+(?!\d))/g,
        ","
      );
      integerPart = otherNumbers
        ? formattedOtherNumbers + "," + lastThree
        : lastThree;
    }

    // Concatenate prefix and formatted number
    return `${prefix || ""}${integerPart}${decimalPart}`;
  };

  return (
    <span>
      {displayType === "text" ? (
        formatNumber(value)
      ) : (
        <input type="text" value={formatNumber(value)} readOnly />
      )}
    </span>
  );
};

export default CurrencyFormat;
