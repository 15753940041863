import React, { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../../assets/images/header/logofront.svg";
import call from "../../assets/images/header/call.png";
import email from "../../assets/images/header/email.png";
export default function Header({
  navigateMenu,
  activeMenu,
  activeSlider,
  defActive,
}) {
  const dropdownRef = useRef(null);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const [buttonText, setButtonText] = useState("Avail Offers");
  const [toggle, setToggle] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle((prevToggle) => !prevToggle);
      setButtonText((prevText) =>
        prevText === "Avail Offers" ? "Sign In" : "Avail Offers"
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <header className="header_section">
        <div className="container">
          <nav className="navbar navbar-expand-xl custom_nav-container ">
            <Link
              className="navbar-brand"
              to="/"
              style={{ marginRight: "0px" }}
            >
              <img
                src={logo}
                className="img-fluid"
                style={{ height: "36px" }}
                alt="finlab"
              />
            </Link>

            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className=""> </span>
          </button> */}
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleNavbar}
              aria-controls="navbarSupportedContent"
              aria-expanded={isNavbarCollapsed ? "false" : "true"}
              aria-label="Toggle navigation"
            >
              <span className=""> </span>
            </button>

            {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
            <div
              className={`collapse navbar-collapse ${
                isNavbarCollapsed ? "" : "show"
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mx-auto ">
                <li
                  className={
                    activeMenu === "home" ? "nav-item active" : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li
                  className={
                    activeMenu === "mechanicsone"
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <NavLink className="nav-link" to="/about-us">
                    About Us
                  </NavLink>
                </li>
                <li
                  className={`nav-item dropdown ${isOpen ? "show" : ""} ${
                    activeMenu === "services" ? "active" : ""
                  }`}
                  ref={dropdownRef}
                >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="servicesDropdown"
                    role="button"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default anchor behavior
                      toggleDropdown();
                      // navigateMenu("services");
                    }}
                    aria-expanded={isOpen ? "true" : "false"}
                  >
                    Services
                  </a>
                  <ul className={`dropdown-menu ${isOpen ? "show" : ""}`}>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/business-loan"
                        title="Learn more about Business Loans"
                      >
                        Business Loan
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/personal-loan"
                        title="Learn more about Personal Loans"
                      >
                        Personal Loan
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className={"nav-item"}>
                  <NavLink className="nav-link" to="/partner">
                    Partner With Us
                  </NavLink>
                </li>
                <li className={"nav-item"}>
                  <NavLink className="nav-link" to="/blogs">
                    Blogs
                  </NavLink>
                </li>
              </ul>
              <div className="user_option">
                <Link to="" className="user_link">
                  <div className="user_call">
                    <img
                      src={call}
                      className="img-fluid"
                      style={{ height: "15px" }}
                      alt=""
                    />{" "}
                    +919837010594
                  </div>
                  <div className="user_email">
                    <img
                      src={email}
                      className="img-fluid"
                      style={{ height: "15px" }}
                      alt=""
                    />{" "}
                    care@finlab.one
                  </div>
                </Link>

                <Link to="/login" className="order_online avail-btn">
                  <div className={`avail-offers  ${toggle ? "animate" : ""}`}>
                    {buttonText}
                  </div>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
}
