import AdminHeader from "../../layouts/admin-header";
import AdminFooter from "../../layouts/admin-footer";
import AdminNavBar from "../../layouts/admin-nav-bar";
import { useState, useEffect } from "react";
import Loader from "../../loader";
import axios from "axios";
import { API_URL } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";
export default function BranchList({ menuAccess }) {
  const [loader, setLoader] = useState(false);
  const [branches, setBranches] = useState([]);
  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("branchName");

  useEffect(() => {
    getBranches();
  }, []);

  const getBranches = async () => {
    setLoader(true);
    try {
      const response = await axios.get(API_URL + "branch/list-branches");
      console.log(response.data);
      const { status, data } = response.data;
      if (status === 200) {
        setBranches(data);
      } else {
        toast.error("Failed to fetch branches");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch branches");
    } finally {
      setLoader(false);
    }
  };

  const customConfirm = (message, onConfirm) => {
    if (window.confirm(message)) {
      onConfirm();
    }
  };

  const handleToggleStatus = async (branchId, currentStatus) => {
    customConfirm(
      currentStatus === 90
        ? "Are you sure want to delete this branch?"
        : "Are you sure you want to change the status of this branch?",
      async () => {
        setLoader(true);
        try {
          const response = await axios.post(
            API_URL + `branch/update-branch-status`,
            { status: currentStatus, branchId: branchId }
          );
          const { status, message } = response.data;
          if (status === 200) {
            toast.success(message);
            getBranches();
          } else {
            toast.error(message);
          }
        } catch (error) {
          toast.error("Failed to update branch status");
        } finally {
          setLoader(false);
        }
      }
    );
  };

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className="layout-wrapper">
        <div className="layout-container">
          <AdminNavBar menuAccess={menuAccess} />
          <div className="adminMain-wrapper">
            <AdminHeader />
            <div className="mainContent">
              <div className="topHeadings mb-4">
                <h3>Branch List</h3>
              </div>
              <div className="contentBlocks">
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="card tableLead">
                      <div className="tableLeadCase">
                        <table className="table table-stripped">
                          <thead>
                            <tr>
                              <th>Branch ID</th>
                              <th>Branch Name</th>
                              <th>Contact Person</th>
                              <th>Contact Number</th>
                              <th>Email ID</th>
                              <th>Status</th>
                              <th
                                style={{
                                  width: "300px",
                                  textAlign: "center",
                                }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {branches.map((branch) => (
                              <tr key={branch.id}>
                                <td>{branch?.partner_id}</td>
                                <td>{branch?.register_as}</td>
                                <td>{branch?.name}</td>
                                <td>{branch.mobile_number}</td>
                                <td>{branch.email_id}</td>
                                <td>
                                  <span
                                    style={{
                                      color:
                                        branch.status === 1 ? "green" : "red",
                                    }}
                                  >
                                    {branch.status === 1
                                      ? "Active"
                                      : "Inactive"}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    width: "300px",
                                    textAlign: "center",
                                  }}
                                >
                                  <button
                                    className="btn btn-primary"
                                    onClick={() =>
                                      handleToggleStatus(
                                        branch.id,
                                        branch.status === 1 ? 0 : 1
                                      )
                                    }
                                  >
                                    {branch.status === 1
                                      ? "Deactivate"
                                      : "Activate"}
                                  </button>
                                  <button
                                    className="btn btn-warning"
                                    onClick={() =>
                                      alert(
                                        "Edit functionality not implemented"
                                      )
                                    }
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      handleToggleStatus(branch.id, 90)
                                    }
                                  >
                                    Delete
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}
