export const API_URL =
  process.env.REACT_APP_ENV === "prod"
    ? "https://byndfinserve.in/"
    : process.env.REACT_APP_ENV === "test"
    ? "https://testing.byndfinserve.in/"
    : "http://localhost:8080/";
export const SIGNZY_USERNAME = "finlab_test";
export const SIGNZY_PASSWORD = "comumlthlnexop86";
export const SIGNZY_URL =
  process.env.REACT_APP_ENV === "prod"
    ? "https://preproduction.signzy.tech/api/v2/"
    : "https://preproduction.signzy.tech/api/v2/";

//http://34.27.206.42:5000/
