import React, { useEffect } from "react";
import BackgroundImage from "../../assets/images/hide.jpg";
import ImagePersonalLoan from "../../assets/images/Personal-Loan.jpg";
import styled, { keyframes } from "styled-components";
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
import { Helmet } from "react-helmet";
const zoomInOut = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const HeroSection = styled.header`
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: ${zoomInOut} 10s infinite;
    z-index: 1;
  }
  .container {
    z-index: 3;
  }
`;
const HeroTitle = styled.h1`
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const HeroSubtitle = styled.p`
  color: #fff;
  font-size: 1rem;
  z-index: 3;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;
const StyledDiv = styled.div`
  .section {
    margin: 50px 0;
  }
  .mission-values h3,
  h3 {
    color: #1a73e8; /* Adjust color as needed */
  }
  .signature {
    font-family: "Cursive", sans-serif;
    font-size: 24px;
  }
`;
const PersonalLoanContent = () => {
  const dynamicClassName = "footer_section custom_footer_style";
  const navigateMenu = (section) => {};

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Apply for a Personal Loan online with FinLab One</title>
        <meta
          name="title"
          content="Apply for a Personal Loan online with FinLab One"
        />
        <meta
          name="description"
          content="Get an instant Personal Loan with FinLab One. Fast Approval, Transparent Process, Flexible Tenure & Repayment Options, Available Multiple Borrowing Options."
        />
        <link rel="canonical" href="https://www.finlab.one/personal-loan" />
        <meta
          name="keywords"
          content="personal loan, fast personal loan, loan options, flexible loans, low interest loans"
        />

        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LoanOrCredit",
              "name": "Personal Loan",
              "description": "Quick personal loan application process with competitive interest rates and flexible repayment plans.",
              "loanType": "Personal Loan",
              "loanTerm": "Flexible terms",
              "interestRate": "Starting at 3.9%",
              "amount": "Up to ₹50,000",
              "url": "https://finlab.one/personal-loan"
            }
          `}
        </script>
      </Helmet>
      <Header navigateMenu={navigateMenu} />
      <HeroSection
        className="header-image ken-burn-center light"
        data-parallax="true"
        data-natural-height="500"
        data-natural-width="1920"
        data-bleed="0"
        data-image-src={BackgroundImage}
        data-offset="0"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <HeroTitle>Personal Loan</HeroTitle>
              <HeroSubtitle>
                Quick and Easy Loans to Meet Your Personal Needs
              </HeroSubtitle>
            </div>
          </div>
        </div>
      </HeroSection>
      <StyledDiv>
        <div className="section">
          <div className="container">
            <h3>Resolve Your Financial Requirements with a Personal Loan</h3>
            <div className="row">
              <div className="col-md-12">
                <img
                  src={ImagePersonalLoan}
                  alt="Personal Loan"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="col-md-12">
                <p>
                  Are you trying to borrow a personal loan for household needs
                  and are not able to get it due to less accessibility or other
                  eligibility criteria? We have the perfect solution for your
                  financial crisis. We have been helping borrowers get{" "}
                  <strong>personal loans</strong> for multiple uses, especially
                  for those having good incomes and who are capable of repaying
                  the loan.
                </p>{" "}
                <p>
                  We provide the best lending services where there is affordable
                  and competitive interest with all-time availability and
                  accessibility, which can make your borrowing experience much
                  better.
                </p>
                <p>
                  For your multiple financial needs, whether for household needs
                  or emergencies, we fulfill your needs as a trusted lending
                  partner instantly.
                </p>
              </div>
            </div>
            <h3>For Your Every Personal Need:</h3>
            <p>
              Our personal loan is available for multiple needs such as medical
              emergencies, travel plans, home renovation, education, or debt
              consolidation. It provides flexibility and instant financial
              support, especially if you need to manage your financial planning.{" "}
            </p>
            <h3>Key Features of Our Personal Loan:</h3>
            <ul>
              <li>Loan amounts from ₹50,000 to ₹10,00,000 </li>
              <li>Flexible repayment terms (6 months to 5 years)</li>
              <li>Quick disbursement (within 24 hours)</li>
              <li>Minimal paperwork</li>
              <li>Fast approval</li>
            </ul>

            <h3>Why Choose Finlab</h3>
            <p>
              Finlab is your trusted financial partner, helping you have an
              outstanding lending experience you might not have found anywhere
              else. Choosing any lending partner indeed depends on the features
              that make your borrowing easy. The features and benefits of our
              loan include:
            </p>

            <ul>
              <li>
                <strong>Instant loan approval:</strong> Get approval for your
                loan in a few minutes and start using your amount for your
                desired purpose.
              </li>
              <li>
                <strong>Easy Application with 24x7 availability:</strong> Easily
                apply for a personal loan and get approval shortly.
              </li>
              <li>
                <strong>No collateral required:</strong> For an unsecured loan,
                no collateral will be required, making your loan approval easy.
              </li>
              <li>
                <strong>Flexible tenure:</strong> With multiple EMIs, you will
                have the freedom to choose the tenure which will make your
                repayment easy.
              </li>
              <li>
                <strong>Competitive Interest:</strong> Enjoy the freedom of
                competitive interest for the affordable repayment options that
                will make your borrowing easy. 
              </li>
              <li>
                <strong>Multiple Usage:</strong> Due to having multiple uses,
                you can also borrow a{" "}
                <strong>personal loan for students,</strong> and other purposes
                because of no restriction on its usage.
              </li>
            </ul>

            <h3>Steps to Apply for a Personal Loan</h3>
            <ol>
              <li>Click on the icon Apply Now.</li>
              <li>Enter your details and fill out the form.</li>
              <li>Upload the softcopy of your documents.</li>
              <li>Get the verification and approval for the loan.</li>
              <li>
                Follow the instructions and accept the terms & conditions.
              </li>
              <li>Get the disbursal of your loan amount.</li>
            </ol>

            <h3>Eligibility Criteria</h3>
            <p>
              Check your eligibility with certain criteria fixed as per the
              lender's policy and these are:
            </p>

            <table border="1">
              <thead>
                <tr>
                  <th>Serial No</th>
                  <th>Factors</th>
                  <th>Eligibility Criteria</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Age</td>
                  <td>
                    The age of the applicant should be above 18 and up to 65.
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Income</td>
                  <td>
                    The minimum income is ₹15,000 per month and can vary as per
                    the loan amount.
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Credit Score</td>
                  <td>650</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Nationality</td>
                  <td>Indian</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Terms of Employment</td>
                  <td>
                    The employee must be associated with a government
                    organization, private limited company, or registered
                    institution.
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Documents Required</td>
                  <td>
                    Aadhar card, PAN, Salary slip, Bank statement, ITR, utility
                    bills, Rent Agreement, and others as per the lender's
                    requirements.
                  </td>
                </tr>
              </tbody>
            </table>

            <h3>Documents Required</h3>
            <h5>Identity Proof</h5>
            <ul>
              <li>Aadhar Card</li>
              <li>PAN</li>
              <li>Driving Licence</li>
              <li>Passport</li>
              <li>Voter ID</li>
            </ul>

            <h5>Transactional and Financial Proof</h5>
            <ul>
              <li>Bank Statement of 6 Months</li>
              <li>Salary Slip of Last 3 Months</li>
              <li>ITR</li>
            </ul>

            <h5>Residential Proof</h5>
            <ul>
              <li>Rent Agreement, Utility Bills,Ownership of Home</li>
            </ul>

            <h2>FAQs</h2>
            <div className="faq">
              <h5>Q. How can I apply for a ₹50,000 personal loan?</h5>
              <p>
                Ans. You can apply for a personal loan directly from our
                website. Go to the application page, fill in your details, and
                upload your documents.
              </p>

              <h5>
                Q. What are the eligibility criteria for a ₹1 lakh personal
                loan?
              </h5>
              <p>
                Ans. The eligibility criteria for a{" "}
                <strong>1 lakh personal loan</strong> depends on age, income,
                credit score, and documents. For more details, you can check the
                eligibility criteria for the personal loan.
              </p>

              <h5>Q. Can I apply for a personal loan for a student?</h5>
              <p>
                Ans. Yes, you can apply for a personal loan for students where
                you must be eligible whether as a student or salaried person. In
                many cases. The application from co-applicants are also
                accepted.
              </p>

              <h5>Q. How much time will it take to get the approval?</h5>
              <p>
                Ans. If your documents are complete and you are eligible for a
                loan considering all the factors, the loan approval and
                disbursal will be done shortly.
              </p>

              <h5>Q. What are the fees applied to the personal loan?</h5>
              <p>
                Ans. There are processing charges and interest rates which might
                be negotiable and can vary as per the income and credit score.
                But we can assure you that there are no hidden charges. 
              </p>
            </div>
          </div>
        </div>
      </StyledDiv>
      <Footer className={dynamicClassName} />
    </>
  );
};

export default PersonalLoanContent;
