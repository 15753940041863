import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import MoneyViewLogo from "../../assets/images/congratulations/logo-light.png";
import Celebration from "../../assets/images/congratulations/gift.png";
import FinlabLogo from "../../assets/images/header/logoback.svg";

export default function Index() {
  // Function to detect the user is on a which device
  const isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);

  const buttonText = "Download App";
  const buttonLink = isApple
    ? "https://apps.apple.com/in/app/moneyview-loans-investments/id6468976019"
    : "https://play.google.com/store/apps/details?id=com.whizdm.moneyview.loans&hl=en_IN&gl=IN&pli=1";

  return (
    <Container fluid className="loan-offer-container">
    {/* Header Section */}
    <Row className="congratulations-header align-items-center">
    <Col xs={12} md={6} className="text-start">
        <img
          src={FinlabLogo}
          alt="Finlab Logo"
          className="congratulations-logo"
        />
        <span className="mx-2">in partnership with</span>
        <img
          src={MoneyViewLogo}
          alt="MoneyView Logo"
          className="congratulations-logo"
        />
      </Col>
      <Col xs={12} md={6} className="text-end mt-3 mt-md-0">
        <i className="bi bi-person-circle congratulations-user-icon"></i>
      </Col>
    </Row>

    {/* Content Section */}
    <Row className="congratulations-content text-center justify-content-center my-4">
      <Col xs={12} sm={10} md={8} lg={6}>
        <div className="congratulations-offer-card">
          <div className="congratulations-icon-container">
            <i className="bi bi-gift-fill congratulations-offer-icon">
              <img
                src={Celebration}
                alt="Celebration symbol"
                className="celebration-symbol"
              />
            </i>
          </div>
          <h2>Congratulations!</h2>
          <p>
            You are eligible for a loan of <strong>₹3,00,000*</strong>
          </p>
          <p>Get loan offers up to ₹5 Lakh on our app</p>
          <p className="congratulations-disclaimer">
            *This is a tentative loan offer and the final loan offer might be
            subject to change
          </p>
          <Button
            variant="success"
            className="congratulations-download-button"
            onClick={() => window.open(buttonLink, "_blank")}
          >
            {buttonText}
          </Button>
        </div>
      </Col>
    </Row>

    {/* Footer Section */}
    <Row className="congratulations-footer text-center">
      <Col xs={12}>
        <p>Safe and secure &nbsp; | &nbsp; Privacy</p>
        <p>© 2024 Finlab.One Pvt. Ltd.</p>
      </Col>
    </Row>
  </Container>
  );
}
