import AdminHeader from "../../layouts/admin-header";
import AdminFooter from "../../layouts/admin-footer";
import AdminNavBar from "../../layouts/admin-nav-bar";
import { useState } from "react";
import Loader from "../../loader";
import axios from "axios";
import { API_URL } from "../../../config/constant";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function CreateBranch({ menuAccess }) {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    branchId: "",
    branchName: "",
    contactPersonName: "",
    contactNumber: "",
    emailId: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const history = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.branchId) tempErrors.branchId = "Branch ID is required";
    if (!formData.branchName) tempErrors.branchName = "Branch Name is required";
    if (!formData.contactPersonName)
      tempErrors.contactPersonName = "Contact Person Name is required";
    if (!formData.contactNumber)
      tempErrors.contactNumber = "Contact Number is required";
    if (!formData.emailId) tempErrors.emailId = "Email ID is required";
    if (!formData.password) tempErrors.password = "Password is required";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      setLoader(true);
      try {
        const response = await axios.post(
          API_URL + "branch/add-branch",
          formData
        );
        console.log(response.data);
        const { status, message } = response.data;
        if (status === 200) {
          toast.success(message);
          history("/admin/branch/list");
        } else {
          toast.success(message);
        }
        // Handle success
      } catch (error) {
        console.error(error);
        // Handle error
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className="layout-wrapper">
        <div className="layout-container">
          <AdminNavBar menuAccess={menuAccess} />
          <div className="adminMain-wrapper">
            <AdminHeader />
            <div className="mainContent">
              <div className="topHeadings mb-4">
                <h3>Add Branch</h3>
              </div>
              <div className="contentBlocks">
                <div className="sectionTable">
                  <div className="continer-fluid">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="email">
                            Branch ID<span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Branch ID"
                            name="branchId"
                            value={formData.branchId}
                            onChange={handleChange}
                          />
                          {errors.branchId && (
                            <span className="error">{errors.branchId}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="email">
                            Branch Name<span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Branch Name"
                            name="branchName"
                            value={formData.branchName}
                            onChange={handleChange}
                          />
                          {errors.branchName && (
                            <span className="error">{errors.branchName}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="email">
                            Contact Person Name
                            <span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contact Person Name"
                            name="contactPersonName"
                            value={formData.contactPersonName}
                            onChange={handleChange}
                          />
                          {errors.contactPersonName && (
                            <span className="error">
                              {errors.contactPersonName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="email">
                            Contact Number
                            <span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contact Number"
                            name="contactNumber"
                            value={formData.contactNumber}
                            onChange={handleChange}
                          />
                          {errors.contactNumber && (
                            <span className="error">
                              {errors.contactNumber}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="email">
                            Email ID
                            <span className="error">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email ID"
                            name="emailId"
                            value={formData.emailId}
                            onChange={handleChange}
                          />
                          {errors.emailId && (
                            <span className="error">{errors.emailId}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <div className="form-group">
                          <label for="email">
                            Password
                            <span className="error">*</span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                          />
                          {errors.password && (
                            <span className="error">{errors.password}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 col-xs-12">
                        <div
                          className="form-group"
                          style={{
                            display: "Flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button type="reset" className="btn btn-secondary">
                            Reset
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}
