import React, { useContext, useEffect } from "react";
import Header from "./../layouts/header";
import Footer from "./../layouts/footer";
import { Helmet } from "react-helmet";
import { MessageContext } from "../../Context/message-context";
import { useNavigate } from "react-router-dom";

export default function ThankYou() {
  const { message } = useContext(MessageContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!message) {
      navigate("/partner");
    }
  }, [message, navigate]);

  return (
    <>
      <Helmet>
        <title>Thank You - FinLab</title>
        <meta
          name="description"
          content="Thank you for partnering with FinLab."
        />
      </Helmet>
      <div className="hero_area">
        <Header />
        <div
          className="thank-you-area"
          style={{
            height: "55vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Thank You!</h2>
                <p>{message}</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
