import AdminHeader from "../../layouts/admin-header";
import AdminFooter from "../../layouts/admin-footer";
import AdminNavBar from "../../layouts/admin-nav-bar";
import { useState, useEffect } from "react";
import Loader from "../../loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../../../config/constant";
import Swal from "sweetalert2";
export default function ManageCategory({ menuAccess }) {
  const [loader, setLoader] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [categoryError, setCategoryError] = useState(false);
  const handleCategory = (e) => {
    setCategory(e.target.value);
    setCategoryError(false);
  };
  const submitCategoryForm = async () => {
    if (category === "") {
      setCategoryError(true);
      return false;
    }
    setLoader(true);
    try {
      const response = await axios.post(`${API_URL}blog/add-category`, {
        name: category,
      });
      const { data, status, message } = response?.data;
      if (status === 200) {
        setCategoryList(data);
        setCategory("");
        toast.success("Category Added Successfully!");
      } else {
        toast.error(message);
      }
    } catch (error) {
      // Handle error
      toast.error("There was an error adding the category!");
      console.error("There was an error adding the category!", error);
    } finally {
      setLoader(false);
    }
  };

  const deleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        try {
          const response = await axios.put(
            `${API_URL}blog/category/${id}/status`,
            { status: 90 }
          );
          const { data, status } = response?.data;
          if (status === 200) {
            setCategoryList(data);
            Swal.fire("Deleted!", "Category has been deleted.", "success");
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an error deleting the category.",
            "error"
          );
        } finally {
          setLoader(false);
        }
      }
    });
  };

  const updateCategoryStatus = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to ${
        newStatus === 1 ? "activate" : "deactivate"
      } this category.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        try {
          const response = await axios.put(
            `${API_URL}blog/category/${id}/status`,
            { status: newStatus }
          );
          const { data, status } = response?.data;
          if (status === 200) {
            setCategoryList(data);
            Swal.fire(
              "Updated!",
              "Category status updated successfully.",
              "success"
            );
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an error updating the category status.",
            "error"
          );
        } finally {
          setLoader(false);
        }
      }
    });
  };

  const fetchCategoryList = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${API_URL}blog/categories`);
      const { data, status } = response?.data;
      if (status === 200) {
        setCategoryList(data);
      }
    } catch (error) {
      console.error("There was an error fetching the category list!", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className="layout-wrapper">
        <div className="layout-container">
          <AdminNavBar menuAccess={menuAccess} />
          <div className="adminMain-wrapper">
            <AdminHeader />
            <div className="mainContent">
              <div className="topHeadings mb-4">
                <h3>Category</h3>
              </div>
              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="continer-fluid">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Add Category"
                          onChange={handleCategory}
                          value={category}
                          style={categoryError ? { borderColor: "red" } : {}}
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-xs-6">
                        <input
                          type="button"
                          className="btn btn-primary"
                          value="Add Category"
                          onClick={submitCategoryForm}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contentBlocks">
                <div className="sectionTable">
                  <div className="continer-fluid">
                    <div className="row">
                      <div className="col-lg-12 col-md-9 col-xs-12">
                        <div className="card tableLead">
                          <div className="tableLeadCase">
                            <table
                              style={{ width: "100%" }}
                              className="table table-stripped"
                            >
                              <thead>
                                <tr>
                                  <th className="table-head">S.No.</th>
                                  <th className="table-head">Name</th>
                                  <th className="table-head">No. of Blogs</th>
                                  <th className="table-head">Status</th>
                                  <th className="table-head">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {categoryList.map((category, index) => (
                                  <tr key={category.id}>
                                    <td>{index + 1}</td>
                                    <td>{category.name}</td>
                                    <td>
                                      {category.blogCount
                                        ? category.blogCount
                                        : 0}
                                    </td>
                                    <td>
                                      {category.status === 1
                                        ? "Active"
                                        : "Inactive"}
                                    </td>
                                    <td>
                                      <button className="btn btn-sm btn-warning" title="Edit Category">
                                        <i
                                          class="fa fa-pencil-square-o"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <button
                                        className={`btn btn-sm ${
                                          category.status === 1
                                            ? "btn-primary"
                                            : "btn-primary"
                                        }`}
                                        onClick={() =>
                                          updateCategoryStatus(
                                            category.id,
                                            category.status
                                          )
                                        }
                                        style={{ marginLeft: "5px" }}
                                        title={
                                          category.status === 1
                                            ? "Inactive Category"
                                            : "Active Category"
                                        }
                                      >
                                        {category.status === 1 ? "☓" : "✓"}
                                      </button>
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          deleteCategory(category.id)
                                        }
                                        style={{ marginLeft: "5px" }}
                                        title="Delete Category"
                                      >
                                        <i
                                          class="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}
